import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output
} from '@angular/core';
import {
    FlowObjectType,
    FlowObjectDefinition,
    FormSchema
} from '../../../../models/flow-object.model';
import {
    Organizacao,
    Unidade
} from '../../../../models/organograma.model';
import {
    ConjuntoGrupo,
    TipoFiltro
} from '../../../../models/acesso-cidadao.model';
import {
    GruposFilter,
    OrganizacoesFilter,
    UnidadesFilter
} from '../flow-object-details.pipe';
import {
    ApiResolver,
    AuthenticationModel,
    AuthenticationSchemeType,
    AuthenticationSchemeTypeDescription,
    ConfigSchema
} from '../../../../models/config-schema.model';
import { FlowDefinition } from '../../../../models/flow.model';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../../shared/enums';
import { FLOW_OBJECT_DETAILS_OUTBOUND_API_TINYMCE_OPTIONS } from './flow-object-details-outbound-api-tinymce-options';
import { Utils } from '../../../../shared/utils';
import { OrganogramaService } from '../../../../services/organograma.service';
import { AcessoCidadaoService } from '../../../../services/acesso-cidadao.service';
import { IBaseOption } from '../../../../models/base.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FlowObjectDefinitionService } from '../../../../services/flow-object-definition.service';

@Component({
    selector: 'flow-object-details-outbound-api',
    templateUrl: './flow-object-details-outbound-api.component.html',
    styleUrls: ['./flow-object-details-outbound-api.component.scss']
})
export class FlowObjectDetailsOutboundApiComponent implements OnInit {
    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    AuthenticationSchemeType: typeof AuthenticationSchemeType = AuthenticationSchemeType;
    // #endregion

    // #region [properties]
    FormTypeStartTag = '{$|form|$}_' as const;
    FormTypeSeparatorTag = '{$|_|$}' as const;
    FormTypeEndTag = '_{$|form|$}' as const;
    PairValueTypeValues = {
        String: '{$|string|$}',
        Number: '{$|number|$}',
        Boolean: '{$|bool|$}',
        Null: '{$|null|$}',
        EDocs: '{$|edocs|$}',
        Form: `${this.FormTypeStartTag}{0}${this.FormTypeSeparatorTag}{1}${this.FormTypeEndTag}`
    } as const;
    apiResponseExample = {
        'sucesso': true,
        'mensagem': 'Certificado de Conclusão de Curso emitido com sucesso.'
    } as const;

    model: FlowObjectDefinition;
    configSchema: ConfigSchema = new ConfigSchema();
    url: string = '';
    pairKey: string = '';
    pairValue: string = '';
    pairValueType: string;
    id: string = '';
    fullJson: string = '';
    jsonToEdit: string = '';
    publicSystemName: string = '';
    keyValuePairsArray: any[] = [];
    keyValuePairsObject: any = {};
    formFlowObjectEntries: any[] = [];
    formFlowObject: FlowObjectDefinition;
    tinyMceOptions: any = FLOW_OBJECT_DETAILS_OUTBOUND_API_TINYMCE_OPTIONS;
    showJsonView: boolean = false;
    previousShowJsonView: boolean = false;
    authentication: AuthenticationModel = new AuthenticationModel();
    ignoreError: boolean = false;
    errorMessage?: string = null;
    authenticationSchemeOptions: IBaseOption[] = [];
    resolver: ApiResolver = new ApiResolver();
    resolverName: string = null;
    // #region [Organização]
    organizationId: string = '';
    organizationDisplay: string = '';
    organizationName: string = null;
    organizations: Organizacao[] = [];
    selectableOrganizations: Organizacao[] = [];
    // #endregion
    // #region [Unidade]
    unitId: string = '';
    unitDisplay: string = '';
    unitName: string = null;
    units: Unidade[] = [];
    selectableUnits: Unidade[] = [];
    // #endregion
    // #region [Grupo]
    groupId: string = '';
    groupDisplay: string = '';
    groupName: string = null;
    groups: ConjuntoGrupo[] = [];
    selectableGroups: ConjuntoGrupo[] = [];
    // #endregion
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectDefinition;
    @Output() inputModelChange = new EventEmitter<FlowObjectDefinition>();
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(
        private toastr: ToastrService,
        private organogramaService: OrganogramaService,
        private acessoCidadaoService: AcessoCidadaoService,
        private flowObjectDefinitionService: FlowObjectDefinitionService,
        private organizacoesFilter: OrganizacoesFilter,
        private unidadesFilter: UnidadesFilter,
        private gruposFilter: GruposFilter
    ) {
        this.pairValueType = this.PairValueTypeValues.String;

        for (let item in AuthenticationSchemeType) {
            if (!isNaN(parseInt(item))) {
                this.authenticationSchemeOptions.push({
                    value: +item,
                    description: AuthenticationSchemeTypeDescription.get(+item)
                });
            }
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        setTimeout(async () => {
            this.model = this.inputModel;
            if (this.model?.configSchema != null) {
                this.configSchema = JSON.parse(this.model.configSchema) as ConfigSchema;
                this.url = this.configSchema.taskOutboundApi.url;
                this.publicSystemName = this.configSchema.taskOutboundApi.publicSystemName;
                this.ignoreError = this.configSchema.taskOutboundApi.ignoreError;
                this.errorMessage = this.configSchema.taskOutboundApi.errorMessage;
                this.authentication = this.configSchema.taskOutboundApi.authentication;
                this.fullJson = JSON.stringify(this.configSchema.taskOutboundApi.data);
                this.setJsonToEdit();

                for (const key in this.configSchema.taskOutboundApi.data) {
                    let value = this.configSchema.taskOutboundApi.data[key];
                    if (!this.isNumber(value) && !this.isBool(value) && !this.isNull(value)) {
                        value = `"${value}"`;
                    }

                    const keyValuePair = JSON.parse(`{ "${key}": ${value} }`);
                    this.keyValuePairsObject = Object.assign(this.keyValuePairsObject, keyValuePair);
                    this.keyValuePairsArray.push(keyValuePair);
                }

                this.resolver = this.configSchema.taskOutboundApi.resolver;

                if (this.resolver.groupId != null) {
                    this.resolverName = this.resolver.groupName;
                } else if (this.resolver.unitId != null) {
                    this.resolverName = this.resolver.unitName;
                }
            }
        }, 1);

        this.authentication.authenticationScheme = AuthenticationSchemeType.None;
        this.formFlowObject = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
        if (this.formFlowObject != null) {
            if (Utils.isNullOrEmpty(this.formFlowObject.formSchema)) {
                const response = await this.flowObjectDefinitionService.getFormData(this.formFlowObject.id);

                if (!response.isSuccess) {
                    this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                this.formFlowObject.formSchema = response.data;
            }

            this.formFlowObjectEntries = this.getFormFlowObjectEntries(this.formFlowObject);
        }

        const response = await this.organogramaService.getOrganizacoesFilhas('fe88eb2a-a1f3-4cb1-a684-87317baf5a57');

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.organizations = response.data;
        this.organizations.sort((a, b) => {
            let first = a.sigla || a.nomeFantasia || a.razaoSocial || '';
            let second = b.sigla || b.nomeFantasia || b.razaoSocial || '';
            return first.localeCompare(second);
        });
        this.selectableOrganizations = this.organizations;
    }

    // ======================
    // public methods
    // ======================

    addKeyValuePair() {
        if (this.isPairValueTypeEmpty()) return;
        if (this.isPairKeyEmpty()) return;
        if (this.isPairKeyAlreadyAdded()) return;
        if (!this.isPairValueValid()) return;

        let keyValuePair = this.parseKeyValuePair();
        this.keyValuePairsArray.push(keyValuePair);
        this.keyValuePairsObject = Object.assign(this.keyValuePairsObject, keyValuePair);
        this.fullJson = JSON.stringify(this.keyValuePairsObject);
        this.setJsonToEdit();
        this.clearKeyValueFields();
    }

    removeKeyValuePair(pair) {
        this.keyValuePairsArray = this.keyValuePairsArray.filter(x => x != pair);

        this.keyValuePairsObject = {};
        this.keyValuePairsArray.forEach(item => {
            this.keyValuePairsObject = Object.assign(this.keyValuePairsObject, item);
        });

        this.fullJson = JSON.stringify(this.keyValuePairsObject);
        this.setJsonToEdit();
    }

    isJsonValid(event?: any): boolean {
        try {
            this.fullJson = this.getJsonToEditText();
            this.keyValuePairsObject = JSON.parse(this.fullJson);
            this.keyValuePairsArray = [];
            for (const key in this.keyValuePairsObject) {
                let value = this.keyValuePairsObject[key];
                if (!this.isNumber(value) && !this.isBool(value) && !this.isNull(value)) {
                    value = `"${value}"`;
                }

                let keyValuePair = JSON.parse(`{ "${key}": ${value} }`);
                this.keyValuePairsArray.push(keyValuePair);
            }

            if (this.showJsonView) {
                this.setJsonToEdit();
                this.jsonToEdit = Utils.jsonSyntaxHighlight(this.jsonToEdit, true);
            }

            this.previousShowJsonView = this.showJsonView;

            return true;
        } catch (error) {
            this.toastr.error(Enums.Messages.JsonDeserializeError, Enums.Messages.Error, this.getToastrErrorOptions());
            console.error(error);
            this.showJsonView = this.previousShowJsonView;
            if (event?.source != null) {
                event.source.checked = this.previousShowJsonView;
            }

            return false;
        }
    }

    toggleShowJsonView() {
        this.showJsonView = !this.showJsonView;
        this.isJsonValid();
    }

    getFormFieldKey(): string {
        return this.pairValueType
            .split(this.FormTypeStartTag).join('')
            .split(this.FormTypeEndTag).join('')
            .split(this.FormTypeSeparatorTag)
            .slice(-1)[0];
    }

    prettyPrint(pair): string {
        let jsonString = JSON.stringify(pair, null, 2);
        return Utils.jsonSyntaxHighlight(jsonString);
    }

    clearKeyValueFields() {
        this.pairKey = null;
        this.pairValue = null;
    }

    authenticationSchemeChangeHandler() {
        if (this.authentication.authenticationScheme != AuthenticationSchemeType.Jwt) {
            this.authentication.clientId = null;
            this.authentication.clientSecret = null;
            this.authentication.scopes = null;
            this.authentication.tokenEndpoint = null;
        }

        if (
            this.authentication.authenticationScheme == AuthenticationSchemeType.None
            || this.authentication.authenticationScheme == AuthenticationSchemeType.Jwt
        ) {
            this.authentication.basicOrApiKey = null;
        }
    }

    shouldDisablePairValueInput(): boolean {
        return this.pairValueType == this.PairValueTypeValues.EDocs
            || this.pairValueType == this.PairValueTypeValues.Null
            || this.isFormPairValueType();
    }

    // #region [Organização]
    organizationDisplayChange() {
        this.selectableOrganizations = this.organizacoesFilter.transform(this.organizations, this.organizationDisplay);
    }

    async organizationIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.organizationId = event.option.value.guid;
        }

        if (this.organizationId != '') {
            let organization = this.organizations.find(x => x.guid == this.organizationId);
            this.organizationName = `${organization.sigla} - ${organization.nomeFantasia == '.' ? organization.sigla : organization.nomeFantasia}`;

            let response = await this.organogramaService.getUnidadesOrganizacao(this.organizationId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.units = response.data;
            this.units.sort((a, b) => {
                let first = a.nomeCurto || a.nome || a.sigla || '';
                let second = b.nomeCurto || b.nome || b.sigla || '';
                return first.localeCompare(second);
            });
            this.selectableUnits = this.units;

            response = await this.acessoCidadaoService.getConjuntoGrupos(this.organizationId, TipoFiltro.TodosGrupoTrabalho);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.groups = response.data;
            this.groups.sort((a, b) => {
                let first = a.nome || '';
                let second = b.nome || '';
                return first.localeCompare(second);
            });
            this.selectableGroups = this.groups;
        }
    }

    displayOrganizationId(option): string {
        return option != null && option != ''
            ? `${option.sigla} - ${option.nomeFantasia == '.' ? option.sigla : option.nomeFantasia}`
            : '';
    }

    clearOrganization() {
        this.organizationId = '';
        this.organizationDisplay = '';
        this.organizationName = null;
        this.selectableOrganizations = this.organizations;
        this.units = [];
        this.clearUnit();
        this.groups = [];
        this.clearGroup();
    }
    // #endregion

    // #region [Unidade]
    unitDisplayChange() {
        this.selectableUnits = this.unidadesFilter.transform(this.units, this.unitDisplay);
    }

    unitIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.unitId = event.option.value.guid;
        }

        this.clearGroup();

        if (this.unitId == '') {
            this.clearUnit();
        } else {
            let unit = this.units.find(x => x.guid == this.unitId);
            this.unitName = (unit.nomeCurto ? unit.nomeCurto.toUpperCase() + ' - ' : '') + unit.nome.toUpperCase();

            this.resolver.unitId = this.unitId;
            this.resolverName = this.unitName
                + ' - ' + this.organizations.find(x => x.guid == this.organizationId).sigla.toUpperCase()
                + ' - ' + this.units.find(x => x.guid == this.unitId).nomeCurto.toUpperCase();
            this.resolver.unitName = this.resolverName;
        }
    }

    displayUnitId(option): string {
        return option != null && option != ''
            ? `${option.nomeCurto ? option.nomeCurto.toUpperCase() + ' - ' : ''}${option.nome.toUpperCase()}`
            : '';
    }

    clearUnit() {
        this.unitId = '';
        this.unitDisplay = '';
        this.unitName = null;
        this.selectableUnits = this.units;
    }
    // #endregion

    // #region [Grupo]
    groupDisplayChange() {
        this.selectableGroups = this.gruposFilter.transform(this.groups, this.groupDisplay);
    }

    groupIdChange(event?: MatAutocompleteSelectedEvent) {
        if (event != null) {
            this.groupId = event.option.value.guid;
        }

        this.clearUnit();

        if (this.groupId == '') {
            this.clearGroup();
        } else {
            this.groupName = this.groups.find(x => x.guid == this.groupId).nome.toUpperCase();

            this.resolver.groupId = this.groupId;
            this.resolverName = this.groupName
                + ' - ' + this.organizations.find(x => x.guid == this.organizationId).sigla.toUpperCase();
            this.resolver.groupName = this.resolverName;
        }
    }

    displayGroupId(option): string {
        return option != null && option != ''
            ? option.nome.toUpperCase()
            : '';
    }

    clearGroup() {
        this.groupId = '';
        this.groupDisplay = '';
        this.groupName = null;
        this.selectableGroups = this.groups;
    }
    // #endregion

    clearResolver() {
        this.resolver = new ApiResolver();
        this.resolverName = '';
    }

    onSubmit() {
        if (this.inputIsReadOnlyMode || !this.isJsonValid()) return false;

        this.configSchema.taskOutboundApi.url = this.url;
        this.configSchema.taskOutboundApi.publicSystemName = this.publicSystemName;
        this.configSchema.taskOutboundApi.ignoreError = this.ignoreError;
        this.configSchema.taskOutboundApi.errorMessage = this.errorMessage;
        this.configSchema.taskOutboundApi.data = JSON.parse(this.fullJson);

        if (this.configSchema.taskOutboundApi.authentication != null) {
            Object.assign(this.configSchema.taskOutboundApi.authentication, this.authentication);
        } else {
            this.configSchema.taskOutboundApi.authentication = this.authentication;
        }

        if (this.configSchema.taskOutboundApi.resolver != null) {
            Object.assign(this.configSchema.taskOutboundApi.resolver, this.resolver);
        } else {
            this.configSchema.taskOutboundApi.resolver = this.resolver;
        }

        this.model.configSchema = JSON.stringify(this.configSchema);

        this.outputSubmitEvent.emit(this.model);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    // ======================
    // private methods
    // ======================

    private getFormFlowObjectEntries(flowObject: FlowObjectDefinition): any[] {
        if (Utils.isNullOrEmpty(flowObject.formSchema)) return;

        let entries = [];
        let formSchema = JSON.parse(flowObject.formSchema) as FormSchema;

        formSchema.components.forEach(item => {
            if (!['button', 'pdfUpload'].includes(item.type)) {
                entries.push({
                    label: item.label,
                    key: `${item.key}`
                });
            }
        });

        return entries;
    }

    private isPairKeyAlreadyAdded(): boolean {
        if (this.keyValuePairsObject.hasOwnProperty(this.pairKey)) {
            this.toastr.error(Enums.Messages.KeyAlreadyAdded, Enums.Messages.Error, this.getToastrErrorOptions());
            return true;
        }

        return false;
    }

    private isPairKeyEmpty(): boolean {
        if (this.pairKey == '' || this.pairKey == null) {
            this.toastr.error(Enums.Messages.EmptyKey, Enums.Messages.Error, this.getToastrErrorOptions());
            return true;
        }

        return false;
    }

    private isPairValueTypeEmpty(): boolean {
        if (this.pairValueType == '' || this.pairValueType == null) {
            this.toastr.error(Enums.Messages.EmptyPairValueType, Enums.Messages.Error, this.getToastrErrorOptions());
            return true;
        }

        return false;
    }

    private isPairValueValid(): boolean {
        if (
            this.pairValueType != '' && this.pairValueType != null
            && (
                (
                    this.pairValueType == this.PairValueTypeValues.String
                    && ( this.pairValue != '' && this.pairValue != null )
                ) || (
                    this.pairValueType == this.PairValueTypeValues.Number
                    && this.isNumber(this.pairValue)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.Boolean
                    && this.isBool(this.pairValue)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.Null
                    && this.isNull(this.pairValue)
                ) || (
                    this.pairValueType == this.PairValueTypeValues.EDocs
                    && this.pairValue !== this.PairValueTypeValues.EDocs
                ) || this.isFormPairValueType()
            )
        ) {
            return true;
        }

        this.toastr.error(Enums.Messages.InvalidPairValue, Enums.Messages.Error, this.getToastrErrorOptions());
        return false;
    }

    private isFormPairValueType(): boolean {
        return this.pairValueType.startsWith(this.FormTypeStartTag)
            && this.pairValueType.includes(this.FormTypeSeparatorTag)
            && this.pairValueType.endsWith(this.FormTypeEndTag);
    }

    private isNumber(value): boolean {
        value = '' + value;
        return !isNaN(value) && !isNaN(parseFloat(value));
    }

    private isBool(value): boolean {
        return ['true', 'false'].includes(value?.toString().trim().toLowerCase());
    }

    private isNull(value): boolean {
        return value === null;
    }

    private parseKeyValuePair(): any {
        try {
            switch (this.pairValueType) {
                case this.PairValueTypeValues.String:
                    return JSON.parse(`{"${this.pairKey}": "${this.pairValue}"}`);

                case this.PairValueTypeValues.Number:
                    return JSON.parse(`{"${this.pairKey}": ${this.pairValue}}`);

                case this.PairValueTypeValues.Boolean:
                    return JSON.parse(`{"${this.pairKey}": ${this.pairValue}}`);

                case this.PairValueTypeValues.Null:
                    return JSON.parse(`{"${this.pairKey}": null}`);

                default:
                    return JSON.parse(`{"${this.pairKey}" : "${this.pairValueType}"}`);
            }
        } catch (error) {
            this.toastr.error(Enums.Messages.JsonSerializeError, Enums.Messages.Error, this.getToastrErrorOptions());
            console.error(error);
        }
    }

    private setJsonToEdit() {
        this.keyValuePairsObject = JSON.parse(this.fullJson);
        this.jsonToEdit = JSON.stringify(this.keyValuePairsObject, null, 2);
        let pre = document.createElement('pre');
        pre.innerHTML = this.jsonToEdit;
        let div = document.createElement('div');
        div.appendChild(pre);
        this.jsonToEdit = div.innerHTML;
    }

    private getJsonToEditText(): string {
        let div = document.createElement('div');
        div.innerHTML = this.jsonToEdit;
        return div.innerText.replace('\n', '');
    }

    private getToastrErrorOptions(): any {
        return {
            closeButton: true,
            timeOut: 10000,
            extendedTimeOut: 5000,
        };
    };
}
