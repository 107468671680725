<ng-container *ngIf="model != null">
    <form class="px-1" (ngSubmit)="onSubmit()" #flowObjectGatewayRules="ngForm">
        <div class="scroll-wrapper px-2">
            <!-- Tipo de regra -->
            <div class="form-group rule-type-container ml-0 mb-3">
                <span>Selecione o tipo de regra que será avaliada pela Tarefa:</span>
                <mat-radio-group class="mb-2" id="httpSuccessRule" name="httpSuccessRule" color="primary"
                                 [(ngModel)]="httpSuccessRule"
                                 (change)="httpSuccessRuleChange()"
                >
                    <mat-radio-button [value]="true">Código HTTP da classe 2XX</mat-radio-button>
                    <div class="ml-5 mt-1">
                        A Tarefa avaliará apenas o código HTTP obtido na requisição realizada à API de destino;
                        caso este seja diferente de <span class="code-font text-highlight">200</span>, o Fluxo será encerrado.
                    </div>
                    <mat-radio-button class="mt-3" [value]="false">Propriedade da API</mat-radio-button>
                    <div class="ml-5 mt-1">
                        A Tarefa decidirá se o Fluxo deve continuar ou se encerrar a partir da avaliação de uma determinada
                        propriedade contida no retorno da requisição realizada à API de destino.
                    </div>
                </mat-radio-group>
            </div>

            <div class="form-group" *ngIf="showProperties">
                <div class="row">
                    <!-- Propriedade -->
                    <div class="form-group col">
                        <label for="propertyKey">Propriedade</label>
                        <i class="fas fa-question-circle ml-1"
                           matTooltip="Nome do campo (contido no retorno da requisição ao endpoint de destino) que conterá o valor a ser avaliado pela regra"
                        ></i>
                        <input type="text" class="form-control" id="propertyKey" name="propertyKey" placeholder="e.g. &quot;userId&quot;" [(ngModel)]="propertyKey">
                    </div>

                    <!-- Operador -->
                    <div class="form-group col">
                        <label for="ruleOperator">Operador</label>
                        <i class="fas fa-question-circle ml-1" matTooltip="Regra a ser avaliada"></i>
                        <select class="form-control" id="ruleOperator" name="ruleOperator" [(ngModel)]="ruleOperator" (change)="ruleOperatorChange()">
                            <option *ngFor="let option of gatewayRuleOperatorOptions" [value]="option.value">{{option.description}}</option>
                        </select>
                    </div>

                    <!-- Valor -->
                    <div class="form-group col">
                        <label for="value">Valor</label>
                        <i class="fas fa-question-circle ml-1"
                           matTooltip="Valor de referência com o qual o valor do campo indicado em &quot;Propriedade&quot; será comparado"
                        ></i>
                        <input type="text" class="form-control" id="propertyValue" name="propertyValue"
                               [(ngModel)]="propertyValue"
                               [disabled]="valueDisabled"
                               placeholder="{{ruleOperator <= GatewayRuleOperator.LessThan
                                   ? 'e.g. &quot;10&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsTrue
                                   ? 'e.g. &quot;true&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsFalse
                                   ? 'e.g. &quot;false&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsNull
                                   ? 'e.g. &quot;null&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsNotNull
                                   ? 'e.g. &quot;Inscrição confirmada. Número: 1234.&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsEmpty
                                   ? 'e.g. &quot;&quot;'
                                   : ruleOperator == GatewayRuleOperator.IsNotEmpty
                                   ? 'e.g. &quot;Registro efetuado.&quot;'
                                   : 'e.g. &quot;termo a ser pesquisado&quot;'
                               }}"
                        >
                    </div>
                </div>

                <!-- Mensagem -->
                <div class="form-group ml-0">
                    <label for="message">Mensagem</label>
                    <i class="fas fa-question-circle ml-1"
                       matTooltip="Mensagem alternativa a ser exibida na Tarefa caso a regra configurada seja avaliada como falsa no momento da execução"
                    ></i>
                    <input type="text" class="form-control" id="message" name="message" placeholder="e.g. &quot;Entre em contato com (...)&quot;" [(ngModel)]="message">
                </div>
            </div>
        </div>

        <!-- Botões -->
        <div class="form-footer text-right mb-1">
            <hr class="mt-0" />
            <button type="button" class="btn btn-outline-danger mr-2" (click)="closeForm()">Cancelar</button>
            <button type="submit" class="btn btn-outline-primary"
                    [disabled]="!flowObjectGatewayRules.form.valid || inputIsReadOnlyMode"
            >Salvar Configurações</button>
        </div>
    </form>
</ng-container>
