<ng-container *ngIf="model != null">
    <form class="px-1" (ngSubmit)="onSubmit()" #flowObjectDetails="ngForm">
        <div class="scroll-wrapper styled-scrollbars px-1">
            <!-- Nome -->
            <ng-container>
                <div class="form-group" featureHint="flowObjectDetails|Name" featureHintExclusiveGroup>
                    <label for="name">Nome</label>
                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="model.name" #nameElem="ngModel" maxlength="100" required>
                </div>
                <div [hidden]="nameElem.valid || nameElem.pristine" class="alert alert-danger">Campo obrigatório</div>
            </ng-container>

            <!-- Nome Público -->
            <ng-container>
                <div class="form-group" featureHint="flowObjectDetails|PublicName" featureHintShowOn="right">
                    <label for="publicName">Nome Público</label>
                    <i class="fas fa-question-circle ml-1"
                       matTooltip="Nome da Tarefa a ser exibido na área pública do E-Flow quando o Fluxo estiver sendo instanciado pelo usuário"
                    ></i>
                    <input type="text" class="form-control" id="publicName" name="publicName" [(ngModel)]="model.publicName" maxlength="100">
                </div>
            </ng-container>

            <!-- Conteúdo exclusivo para Tarefas que exibam a "Mensagem Pública Customizada" -->
            <ng-container *ngIf="shouldShowPublicMessageHtml">
                <div class="form-group" featureHint="flowObjectDetails|PublicMessageHtml" featureHintVAlign="top" featureHintShowOn="right">
                    <label for="publicMessageHtml">Mensagem Pública Customizada</label>
                    <i class="fas fa-question-circle ml-1" matTooltip="Mensagem customizada a ser exibida no corpo da Tarefa na área pública do E-Flow"></i>
                    <editor name="publicMessageHtml" [init]="tinyMceOptions" [(ngModel)]="publicMessageHtml"></editor>
                    <textarea id="tinyMce" class="d-none"></textarea>
                </div>
            </ng-container>

            <!-- Conteúdo exclusivo para Tarefas que gerem captura de Documento como resultado de sua execução -->
            <ng-container *ngIf="shouldShowFlowObjectDetailsDocument">
                <flow-object-details-document #flowObjectDetailsDocumentRef
                                             [(inputConfigSchema)]="configSchema"
                                             [inputFlowDefinition]="inputFlowDefinition"
                                             [inputFlowObjectDefinition]="model"
                                             [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                ></flow-object-details-document>
            </ng-container>

            <!-- Conteúdo exclusivo para Tarefas do tipo "TaskForward" -->
            <ng-container *ngIf="[FlowObjectType.TaskForward].includes(model.typeId)">
                <flow-object-details-forward #flowObjectDetailsForwardRef
                                             [(inputConfigSchema)]="configSchema"
                                             [inputFlowDefinition]="inputFlowDefinition"
                                             [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                                             (outputRecipientModeChange)="updateRecipientMode($event)"
                ></flow-object-details-forward>
            </ng-container>

            <!-- Conteúdo exclusivo para Tarefas do tipo "TaskRegisterProcess" -->
            <ng-container *ngIf="[FlowObjectType.TaskRegisterProcess].includes(model.typeId)">
                <flow-object-details-register-process #flowObjectDetailsRegisterProcessRef
                                                      [(inputConfigSchema)]="configSchema"
                                                      [inputFlowDefinition]="inputFlowDefinition"
                                                      [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                ></flow-object-details-register-process>
            </ng-container>

            <!-- Conteúdo exclusivo para Tarefas do tipo "TaskDispatchProcess" -->
            <ng-container *ngIf="[FlowObjectType.TaskDispatchProcess].includes(model.typeId)">
                <flow-object-details-dispatch-process #flowObjectDetailsDispatchProcessRef
                                                      [(inputConfigSchema)]="configSchema"
                                                      [inputFlowDefinition]="inputFlowDefinition"
                                                      [inputIsReadOnlyMode]="inputIsReadOnlyMode"
                                                      (outputRecipientModeChange)="updateRecipientMode($event)"
                ></flow-object-details-dispatch-process>
            </ng-container>
        </div>

        <!-- Botões -->
        <div class="form-footer text-right mb-1">
            <hr class="mt-0" />

            <!-- ConfigSchema -->
            <div class="float-left ml-1" featureHint="flowObjectDetails|ConfigSchemaButton" featureHintVAlign="bottom">
                <button type="button" class="btn btn-outline-secondary"
                        (click)="showConfigSchema()"
                >Editar código-fonte</button>
            </div>

            <!-- Salvar -->
            <div class="float-right" featureHint="flowObjectDetails|SaveButton" featureHintVAlign="bottom">
                <button type="submit" class="btn btn-outline-primary"
                        [class.read-only]="inputIsReadOnlyMode"
                        [disabled]="
                            !flowObjectDetails.form.valid
                            || (model.typeId == FlowObjectType.TaskForward && !isRadioSelectionValid)
                            || inputIsReadOnlyMode
                        "
                >Salvar Detalhes</button>
            </div>

            <!-- Cancelar -->
            <div class="float-right mr-2" featureHint="flowObjectDetails|CancelButton" featureHintVAlign="bottom">
                <button type="button" class="btn btn-outline-danger"
                        (click)="closeForm()"
                >Cancelar</button>
            </div>
        </div>
    </form>

    <!-- Modal do ConfigSchema -->
    <ng-template #configSchemaRef>
        <textarea id="configSchemaEditor" name="configSchemaEditor" class="form-control" #configSchemaEditorRef
                  style="height: calc(100vh - 78px); resize: none; opacity: 0; transition: opacity .3s ease-in"
                  [ngModel]="configSchemaFlat"
        ></textarea>
    </ng-template>
</ng-container>
