<ng-container *ngIf="model != null">
    <form class="px-1" (ngSubmit)="onSubmit()" #flowDefinitionDetailsHotConfigs="ngForm">
        <div class="scroll-wrapper styled-scrollbars pr-2">
            <!-- Informações -->
            <div class="header ml-1">
                <i class="fas fa-exclamation-circle mt-1 mr-2"></i>

                <div>
                    <p>
                        As configurações desta seção possuem <b>efeito imediato</b> tanto sobre as Definições de Fluxo já publicadas
                        no Portal Público (i.e. no caso do <b>modo Não Listado</b> e das opções relativas à <b>desativação automática</b>)
                        quanto sobre as Instâncias de Fluxo já em execução no Portal Público (i.e. no caso das opções relativas ao
                        <b>cancelamento automático</b>).
                    </p>
                    <span>
                        Assim sendo, não será necessário versionar o Fluxo para realizar e salvar alterações em opções aqui contidas.
                    </span>
                </div>
            </div>

            <hr />

            <!-- Ativar modo Não Listado -->
            <ng-container>
                <div class="form-group checkbox-group pl-1">
                    <mat-checkbox id="isUnlistedModeActive" name="isUnlistedModeActive" color="primary"
                                  featureHint="flowDefinitionDetailsHotConfigs|Unlisted" featureHintShowOn="right"
                                  [(ngModel)]="model.isUnlistedModeActive"
                    >Ativar modo Não Listado</mat-checkbox>
                    <i class="fas fa-question-circle ml-1"
                       matTooltip="Caso a opção esteja ativada, os usuários só poderão iniciar o Fluxo no Portal Público caso possuam seu link direto"
                    ></i>
                </div>
            </ng-container>

            <!-- Habilitar desativação automática -->
            <ng-container>
                <div class="form-group checkbox-group pl-1" featureHint="flowDefinitionDetailsHotConfigs|AutomaticDeactivation" featureHintExclusiveGroup>
                    <mat-checkbox id="hasAutomaticFlowDefinitionDeactivation" name="hasAutomaticFlowDefinitionDeactivation" color="primary"
                                  [(ngModel)]="configSchema.hasAutomaticFlowDefinitionDeactivation"
                                  (change)="clearAutoDeactivateChildren()"
                    >Habilitar a desativação automática desta definição de Fluxo</mat-checkbox>
                    <i class="fas fa-question-circle ml-1"
                       matTooltip="Desativar este Fluxo automaticamente se atingidas as condições de cada opção.
                                   Em caso de múltipla escolha, este Fluxo será desativado assim que a primeira condição for atingida"
                    ></i>
                </div>
                <div class="form-group auto-cancel-option pl-1 ml-5 mb-2"
                     [class.disabled]="!configSchema.hasAutomaticFlowDefinitionDeactivation || !isAutoDeactivateSinceFlowDefinitionActivation"
                >
                    <mat-checkbox class="m-0 mr-2" id="isAutoDeactivateSinceFlowDefinitionActivation" name="isAutoDeactivateSinceFlowDefinitionActivation" color="primary"
                                  [(ngModel)]="isAutoDeactivateSinceFlowDefinitionActivation"
                                  [disabled]="!configSchema.hasAutomaticFlowDefinitionDeactivation"
                                  (change)="clearAutoDeactivateSinceFlowDefinitionActivation($event)"
                    ></mat-checkbox>
                    <span>Após</span>
                    <input type="text" class="form-control mx-1" id="daysSinceFlowDefinitionActivationToAutoDeactivate" name="daysSinceFlowDefinitionActivationToAutoDeactivate"
                           [(ngModel)]="configSchema.daysSinceFlowDefinitionActivationToAutoDeactivate"
                           [disabled]="!configSchema.hasAutomaticFlowDefinitionDeactivation || !isAutoDeactivateSinceFlowDefinitionActivation"
                    >
                    <span>dia(s) desde a última ativação ou publicação desta definição de Fluxo</span>
                </div>
                <div class="form-group auto-cancel-option pl-1 ml-5 mb-2"
                     [class.disabled]="!configSchema.hasAutomaticFlowDefinitionDeactivation || !isAutoDeactivateOnDateTime"
                >
                    <mat-checkbox class="m-0 mr-2" id="isAutoDeactivateOnDateTime" name="isAutoDeactivateOnDateTime" color="primary"
                                  [(ngModel)]="isAutoDeactivateOnDateTime"
                                  [disabled]="!configSchema.hasAutomaticFlowDefinitionDeactivation"
                                  (change)="clearAutoDeactivateOnDateTime($event)"
                    ></mat-checkbox>
                    <span>Na seguinte data e hora:</span>
                    <input type="text" class="datetime form-control mx-1" id="dateTimeToAutoDeactivateFlowDefinition" name="dateTimeToAutoDeactivateFlowDefinition"
                           [(ngModel)]="configSchema.dateTimeToAutoDeactivateFlowDefinition"
                           [disabled]="true"
                           [ngxMatDatetimePicker]="autoDeactivateDatetimePicker"
                    >
                    <mat-datepicker-toggle matSuffix [for]="autoDeactivateDatetimePicker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #autoDeactivateDatetimePicker
                                             [showSpinners]="true"
                                             [showSeconds]="true"
                                             [defaultTime]="[12,00,00]"
                                             [disabled]="!configSchema.hasAutomaticFlowDefinitionDeactivation || !isAutoDeactivateOnDateTime"
                    >
                        <ng-template>
                            <mat-icon>done</mat-icon>
                            <span>OK</span>
                        </ng-template>
                    </ngx-mat-datetime-picker>
                </div>
            </ng-container>

            <!-- Habilitar cancelamento automático -->
            <ng-container>
                <div class="form-group checkbox-group pl-1" featureHint="flowDefinitionDetailsHotConfigs|AutomaticCancellation" featureHintShowOn="right">
                    <mat-checkbox id="hasAutomaticFlowInstanceCancellation" name="hasAutomaticFlowInstanceCancellation" color="primary"
                                  [(ngModel)]="configSchema.hasAutomaticFlowInstanceCancellation"
                                  (change)="clearAutoCancelChildren()"
                    >Habilitar o cancelamento automático das instâncias deste Fluxo</mat-checkbox>
                    <i class="fas fa-question-circle ml-1"
                       matTooltip="Cancelar as instâncias deste Fluxo automaticamente se atingidas as condições de cada opção.
                                   Em caso de múltipla escolha, as instâncias do Fluxo serão canceladas assim que a primeira condição for atingida"
                    ></i>
                </div>
                <div class="form-group auto-cancel-option pl-1 ml-5 mb-2"
                     [class.disabled]="!configSchema.hasAutomaticFlowInstanceCancellation || !isAutoCancelSinceFlowInstanceStart"
                >
                    <mat-checkbox class="m-0 mr-2" id="isAutoCancelSinceFlowInstanceStart" name="isAutoCancelSinceFlowInstanceStart" color="primary"
                                  [(ngModel)]="isAutoCancelSinceFlowInstanceStart"
                                  [disabled]="!configSchema.hasAutomaticFlowInstanceCancellation"
                                  (change)="clearAutoCancelSinceFlowInstanceStart($event)"
                    ></mat-checkbox>
                    <span>Após</span>
                    <input type="text" class="form-control mx-1" id="daysSinceFlowInstanceStartToAutoCancel" name="daysSinceFlowInstanceStartToAutoCancel"
                           [(ngModel)]="configSchema.daysSinceFlowInstanceStartToAutoCancel"
                           [disabled]="!configSchema.hasAutomaticFlowInstanceCancellation || !isAutoCancelSinceFlowInstanceStart"
                    >
                    <span>dia(s) desde o início da instância do Fluxo</span>
                </div>
                <div class="form-group auto-cancel-option pl-1 ml-5 mb-2"
                     [class.disabled]="!configSchema.hasAutomaticFlowInstanceCancellation || !isAutoCancelSinceFlowInstanceLastUpdate"
                >
                    <mat-checkbox class="m-0 mr-2" id="isAutoCancelSinceFlowInstanceLastUpdate" name="isAutoCancelSinceFlowInstanceLastUpdate" color="primary"
                                  [(ngModel)]="isAutoCancelSinceFlowInstanceLastUpdate"
                                  [disabled]="!configSchema.hasAutomaticFlowInstanceCancellation"
                                  (change)="clearAutoCancelSinceFlowInstanceLastUpdate($event)"
                    ></mat-checkbox>
                    <span>Após</span>
                    <input type="text" class="form-control mx-1" id="daysSinceFlowInstanceLastUpdateToAutoCancel" name="daysSinceFlowInstanceLastUpdateToAutoCancel"
                           [(ngModel)]="configSchema.daysSinceFlowInstanceLastUpdateToAutoCancel"
                           [disabled]="!configSchema.hasAutomaticFlowInstanceCancellation || !isAutoCancelSinceFlowInstanceLastUpdate"
                    >
                    <span>dia(s) desde a última atualização na instância do Fluxo</span>
                </div>
                <div class="form-group auto-cancel-option pl-1 ml-5 mb-2"
                     [class.disabled]="!configSchema.hasAutomaticFlowInstanceCancellation || !isAutoCancelOnDateTime"
                >
                    <mat-checkbox class="m-0 mr-2" id="isAutoCancelOnDateTime" name="isAutoCancelOnDateTime" color="primary"
                                  [(ngModel)]="isAutoCancelOnDateTime"
                                  [disabled]="!configSchema.hasAutomaticFlowInstanceCancellation"
                                  (change)="clearAutoCancelOnDateTime($event)"
                    ></mat-checkbox>
                    <span>Na seguinte data e hora:</span>
                    <input type="text" class="datetime form-control mx-1" id="dateTimeToAutoCancelFlowInstance" name="dateTimeToAutoCancelFlowInstance"
                           [(ngModel)]="configSchema.dateTimeToAutoCancelFlowInstance"
                           [disabled]="true"
                           [ngxMatDatetimePicker]="autoCancelDatetimePicker"
                    >
                    <mat-datepicker-toggle matSuffix [for]="autoCancelDatetimePicker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #autoCancelDatetimePicker
                                             [showSpinners]="true"
                                             [showSeconds]="true"
                                             [defaultTime]="[12,00,00]"
                                             [disabled]="!configSchema.hasAutomaticFlowInstanceCancellation || !isAutoCancelOnDateTime"
                    >
                        <ng-template>
                            <mat-icon>done</mat-icon>
                            <span>OK</span>
                        </ng-template>
                    </ngx-mat-datetime-picker>
                </div>
            </ng-container>
        </div>

        <!-- Botões -->
        <div class="form-footer text-right mb-1">
            <hr class="mt-0" />

            <!-- ConfigSchema -->
            <button type="button" class="btn btn-outline-secondary float-left ml-1"
                    (click)="showConfigSchema()"
            >Editar código-fonte</button>

            <!-- Cancelar -->
            <button type="button" class="btn btn-outline-danger mr-2"
                    (click)="closeForm()"
            >Cancelar</button>

            <!-- Salvar -->
            <button type="submit" class="btn btn-outline-primary"
                    [disabled]="!flowDefinitionDetailsHotConfigs.form.valid || !isLatestVersion"
            >Aplicar Configurações Imediatas</button>
        </div>
    </form>

    <!-- Modal do ConfigSchema -->
    <ng-template #configSchemaRef>
        <textarea id="configSchemaEditor" name="configSchemaEditor" class="form-control" #configSchemaEditorRef
                  style="height: calc(100vh - 78px); resize: none; opacity: 0; transition: opacity .3s ease-in"
                  [ngModel]="configSchemaFlat"
        ></textarea>
    </ng-template>
</ng-container>
