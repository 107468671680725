import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Enums } from '../../../shared/enums';
import { Utils } from '../../../shared/utils';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    // #region [Type properties]
    Enums = Enums;
    // #endregion

    // #region [properties]
    tokenTimer: number = null;
    tokenTimerFormatted: string = '00:00';
    isAuthenticated: boolean = false;
    // #endregion

    // #region [getters]
    get isLandingPage(): boolean {
        return window?.location?.pathname == Enums.PagesPaths.LandingPage;
    }
    get showLogInButton() {
        return this.isLandingPage && !this.isAuthenticated;
    }
    // #endregion

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.isAuthenticated = (await this.authService.getStatus()).data.isAuthenticated;

        setInterval(() => {
            let expiration = this.authService.user?.exp;
            let auth = this.authService.user?.auth;
            if (Utils.isNullOrEmpty(expiration) || Utils.isNullOrEmpty(auth)) return;

            let now = Math.floor(Date.now() / 1000);
            this.tokenTimer = +expiration - now;
            //this.tokenTimer = +expiration - +auth;
            //this.tokenTimer = +auth + 3600 - now;
            if (this.tokenTimer < 0) {
                this.tokenTimer = 0;
            }
            this.tokenTimerFormatted = `${Math.floor(this.tokenTimer / 60).toString().padStart(2, '0')}:${(this.tokenTimer % 60).toString().padStart(2, '0')}`;
        }, 1000);
    }

    // ======================
    // public methods
    // ======================

    logIn() {
        this.router.navigate([Enums.PagesPaths.Home]);
    }

    // ======================
    // private methods
    // ======================
}
