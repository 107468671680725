<ng-container *ngIf="model != null">
    <form class="px-1" (ngSubmit)="onSubmit()" #flowObjectGatewayPaths="ngForm">
        <div class="scroll-wrapper styled-scrollbars px-1">
            <!-- Nome da Sequência A -->
            <div class="form-group ml-0">
                <label for="firstGatewayPathName">Nome da Sequência A</label>
                <i class="fas fa-question-circle ml-1"
                    matTooltip="Nome da sequência de Tarefas a ser executada caso o resultado das condições avaliadas para a divisão do Fluxo seja VERDADEIRO"
                ></i>
                <input type="text" class="form-control" id="firstGatewayPathName" name="firstGatewayPathName" [(ngModel)]="firstGatewayPathName" required>
            </div>

            <!-- Nome da Sequência B -->
            <div class="form-group ml-0">
                <label for="secondGatewayPathName">Nome da Sequência B</label>
                <i class="fas fa-question-circle ml-1"
                    matTooltip="Nome da sequência de Tarefas a ser executada caso o resultado das condições avaliadas para a divisão do Fluxo seja FALSO"
                ></i>
                <input type="text" class="form-control" id="secondGatewayPathName" name="secondGatewayPathName" [(ngModel)]="secondGatewayPathName" required>
            </div>

            <!-- Condições para a Divisão do Fluxo -->
            <div class="form-group">
                <h5>Condições para a Divisão do Fluxo</h5>
            </div>

            <!-- Info -->
            <div class="form-group">
                <span>
                    O resultado da avaliação das condições abaixo determinará qual das Sequências de Tarefas
                    nomeadas acima será executada em seguida no Fluxo.
                </span>
            </div>

            <!-- Condições -->
            <div class="form-group">
                <div class="condition-row mb-2" *ngFor="let condition of conditions; let i = index">
                    <!-- Campo do Formulário -->
                    <div class="form-group">
                        <label for="formField_{{i}}">Campo do Formulário</label>
                        <i class="fas fa-question-circle ml-1"
                           matTooltip="Campo do formulário cujo valor será avaliado pela regra. Note que campos do tipo &quot;Lista de Seleção Múltipla&quot; serão ignorados"
                        ></i>
                        <select class="form-control px-1" id="formField_{{i}}" name="formField_{{i}}" required
                                [(ngModel)]="condition.formField"
                                [matTooltip]="getFormFieldDescription(condition)"
                        >
                            <option *ngFor="let option of formFieldOptions"
                                    [value]="option.value"
                            >{{option.description}}</option>
                        </select>
                    </div>

                    <!-- Operador -->
                    <div class="form-group">
                        <label for="ruleOperator_{{i}}">Operador</label>
                        <i class="fas fa-question-circle ml-1" matTooltip="Regra a ser avaliada"></i>
                        <select class="form-control px-1" id="ruleOperator_{{i}}" name="ruleOperator_{{i}}"
                                [(ngModel)]="condition.ruleOperator"
                                [matTooltip]="getRuleOperatorDescription(condition)"
                                (change)="ruleOperatorChange(condition)"
                        >
                            <option *ngFor="let option of gatewayRuleOperatorOptions"
                                    [value]="option.value"
                            >{{option.description}}</option>
                        </select>
                    </div>

                    <!-- Valor -->
                    <div class="form-group">
                        <label for="referenceValue_{{i}}">Valor</label>
                        <i class="fas fa-question-circle ml-1"
                           matTooltip="Valor de referência com o qual o valor indicado em &quot;Campo do Formulário&quot; será comparado"
                        ></i>
                        <input type="text" class="form-control px-1" id="referenceValue_{{i}}" name="referenceValue_{{i}}"
                               [(ngModel)]="condition.referenceValue"
                               [matTooltip]="condition.referenceValue"
                               [disabled]="condition.referenceValueDisabled"
                               placeholder="{{condition.ruleOperator <= GatewayRuleOperator.LessOrEqual
                                   ? '&quot;10&quot;'
                                   : condition.ruleOperator == GatewayRuleOperator.IsTrue
                                   ? '&quot;true&quot;'
                                   : condition.ruleOperator == GatewayRuleOperator.IsFalse
                                   ? '&quot;false&quot;'
                                   : condition.ruleOperator == GatewayRuleOperator.IsNull
                                   ? '&quot;null&quot;'
                                   : condition.ruleOperator == GatewayRuleOperator.IsNotNull
                                   ? '&quot;250&quot;'
                                   : condition.ruleOperator == GatewayRuleOperator.IsEmpty
                                   ? '&quot;&quot;'
                                   : condition.ruleOperator == GatewayRuleOperator.IsNotEmpty
                                   ? '&quot;Opção A&quot;'
                                   : '&quot;(termo a ser pesquisado)&quot;'
                               }}"
                        >
                    </div>

                    <!-- Remover Condição -->
                    <div class="form-group">
                        <label>&nbsp;</label>
                        <i class="fas fa-times-circle action remove" matTooltip="Remover condição" (click)="removeCondition(condition)"></i>
                    </div>
                </div>
            </div>

            <!-- Adicionar Outra Condição -->
            <div class="form-group text-center">
                <button type="button" class="btn btn-outline-success add-condition" (click)="addCondition()">
                    <i class="fas fa-plus-circle mr-2"></i>
                    <span>Adicionar Outra Condição</span>
                </button>
            </div>

            <!-- Operador Lógico -->
            <div class="form-group logical-operator-container ml-0 mb-3" *ngIf="conditions.length > 1">
                <span>Selecione o tipo de operador lógico a ser utilizado na avaliação de múltiplas condições:</span>

                <mat-radio-group class="mb-2" id="isLogicalOperatorAnd" name="isLogicalOperatorAnd" color="primary" [(ngModel)]="isLogicalOperatorAnd">
                    <mat-radio-button [value]="true">E (AND)</mat-radio-button>
                    <div class="ml-4 mt-1">
                        O resultado da avaliação das condições acima será verdadeiro
                        se <strong>todas</strong> as condições forem verdadeiras.
                    </div>

                    <mat-radio-button class="mt-3" [value]="false">OU (OR)</mat-radio-button>
                    <div class="ml-4 mt-1">
                        O resultado da avaliação das condições acima será verdadeiro
                        se <strong>qualquer uma</strong> das condições for verdadeira.
                    </div>
                </mat-radio-group>
            </div>
        </div>

        <!-- Botões -->
        <div class="form-footer text-right mb-1">
            <hr class="mt-0" />
            <button type="button" class="btn btn-outline-danger mr-2" (click)="closeForm()">Cancelar</button>
            <button type="submit" class="btn btn-outline-primary"
                    [disabled]="!flowObjectGatewayPaths.form.valid || inputIsReadOnlyMode"
            >Salvar Configurações</button>
        </div>
    </form>
</ng-container>
