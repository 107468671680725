import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FlowObjectDefinition } from "../../../../models/flow-object.model";
import { FlowDefinition } from "../../../../models/flow.model";
import { ToastrService } from "ngx-toastr";
import { Enums } from "src/app/shared/enums";
import { Utils } from "../../../../shared/utils";
import { IBaseOption } from "../../../../models/base.model";
import { ConfigSchema, GatewayRuleOperator, GatewayRuleOperatorDescription } from "../../../../models/config-schema.model";

@Component({
    selector: "flow-object-details-gateway-rules",
    templateUrl: "./flow-object-details-gateway-rules.component.html",
    styleUrls: ["./flow-object-details-gateway-rules.component.scss"]
})

export class FlowObjectDetailsGatewayRulesComponent implements OnInit {
    // #region [Type properties]
    GatewayRuleOperator: typeof GatewayRuleOperator = GatewayRuleOperator;
    // #endregion

    // #region [properties]
    model: FlowObjectDefinition;
    configSchema = new ConfigSchema();
    message: string;
    httpSuccessRule = false;
    showProperties = false;
    valueDisabled = false;
    propertyKey: string;
    ruleOperator: GatewayRuleOperator = GatewayRuleOperator.Equal;
    propertyValue: any;
    gatewayRuleOperatorOptions: IBaseOption[] = [];
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectDefinition;
    @Output() inputModelChange = new EventEmitter<FlowObjectDefinition>();
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(private toastr: ToastrService) {
        for (let item in GatewayRuleOperator) {
            if (!isNaN(parseInt(item))) {
                this.gatewayRuleOperatorOptions.push({
                    value: +item,
                    description: GatewayRuleOperatorDescription.get(+item)
                });
            }
        }
    }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        setTimeout(async () => {
            this.model = this.inputModel;
            if (this.model?.configSchema != null) {
                this.configSchema = JSON.parse(this.model.configSchema) as ConfigSchema;
                this.httpSuccessRule = this.configSchema.taskGatewayRules.httpSuccessRule;
                this.message = this.configSchema.taskGatewayRules.message;
                this.propertyKey = this.configSchema.taskGatewayRules.propertyKey;
                this.ruleOperator = this.configSchema.taskGatewayRules.ruleOperator;
                this.propertyValue = this.configSchema.taskGatewayRules.propertyValue;
                this.showProperties = !this.httpSuccessRule;
            }

            this.httpSuccessRuleChange();
            this.ruleOperatorChange();
        }, 1);
    }

    // ======================
    // public methods
    // ======================

    onSubmit() {
        if (this.inputIsReadOnlyMode || this.isInvalidConfiguration()) return false;

        this.configSchema.taskGatewayRules.httpSuccessRule = this.httpSuccessRule;
        this.configSchema.taskGatewayRules.message = this.message;
        this.configSchema.taskGatewayRules.propertyKey = this.propertyKey;
        this.configSchema.taskGatewayRules.ruleOperator = this.ruleOperator != null ? Number(this.ruleOperator) : null;
        this.configSchema.taskGatewayRules.propertyValue = this.propertyValue;

        this.model.configSchema = JSON.stringify(this.configSchema);
        this.outputSubmitEvent.emit(this.model);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    httpSuccessRuleChange() {
        if (this.httpSuccessRule) {
            this.showProperties = false;
            this.message = null;
            this.propertyKey = null;
            this.ruleOperator = null;
            this.propertyValue = null;
        } else {
            this.showProperties = true;
        }
    }

    ruleOperatorChange() {
        if (this.ruleOperator < GatewayRuleOperator.IsTrue || this.ruleOperator > GatewayRuleOperator.IsNotEmpty) {
            this.valueDisabled = false;
        } else {
            this.propertyValue = null;
            this.valueDisabled = true;
        }
    }

    // ======================
    // private methods
    // ======================

    private isInvalidConfiguration() {
        if (
            !this.httpSuccessRule
            && (
                Utils.isNullOrEmpty(this.propertyKey)
                || (
                    Utils.isNullOrEmpty(this.propertyValue)
                    && (this.ruleOperator < GatewayRuleOperator.IsTrue || this.ruleOperator > GatewayRuleOperator.IsNotEmpty)
                )
            )
        ) {
            this.toastr.error(Enums.Messages.NullOrEmpty, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return true;
        }

        if (this.ruleOperator < GatewayRuleOperator.IsTrue && isNaN(Number(this.propertyValue))) {
            this.toastr.error(Enums.Messages.IsNotNumber, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return true;
        }

        return false;
    }
}
